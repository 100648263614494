import Footer from "../Footer";
import SecondaryHeader from "../header/SecondaryHeader";

const DataEthics = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-6 sm:py-8 md:py-12">
        <h1 className="flex items-center justify-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-8 sm:mb-10 md:mb-12 lg:mb-16 mt-6 text-unimayt">
          Data Ethics
        </h1>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          In an era where data privacy and ethical practices are crucial,
          Unimayt is committed to placing students' data rights at the forefront
          of our operations. We adhere to the highest standards of data ethics,
          guided by principles that ensure transparency, accountability, and
          respect for our users' personal information. Below, we outline our
          core data principles and practices.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          1. Students at the Center
        </h2>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          At Unimayt, our primary focus is always on the interests of our
          users—students. We ask ourselves, "Does this benefit our students?"
          before making any decision. All information and features on our
          platform are designed to aid students in their academic and social
          journeys. We implement privacy-by-design principles to ensure that
          user data is protected and used responsibly.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          2. Individual Data Control
        </h2>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          On-Device Processing:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          Unimayt does not use profiling for commercial purposes or make
          individual-level behavior predictions. Instead, we employ
          pattern-based analytics to enhance the user experience and support
          organizations in serving their international students more effectively
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Profiling and Predictions:
        </h3>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          It is the User’s responsibility to ensure that their contact
          information is current. If a User does not agree to the amended T&C,
          they must cease use of the Services and deactivate their Account.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          3. Transparency
        </h2>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Data Storage:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          Our data is stored securely in Faridabad, India. For users outside the
          EU, data transmission may occur through countries outside the India,
          which could be subject to local regulations. We strive to be
          transparent about potential risks.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Artificial Intelligence:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          We use algorithms to enhance user interactions, such as a popularity
          algorithm for connecting users based on objective parameters like
          activity and profile completeness. Personal aspects such as gender or
          nationality do not influence these algorithms.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Behavioral Design:
        </h3>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          While notifications are used to maintain engagement and communication,
          we do not use personal data to manipulate user behavior. Our design
          choices are made with user feedback in mind to ensure a balanced
          approach.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          4. Accountability
        </h2>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Anonymity:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          We employ end-to-end encryption and anonymized data processes. Data is
          aggregated and cannot be linked to individuals once shared. Our
          zero-knowledge design principle ensures that only Unimayt can access
          user data.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Data Sharing:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          Unimayt does not sell data to third parties. We may share information
          with relevant organizations, like universities, to improve services
          but do not engage in tracking or advertising based on user behavior.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Data Enrichment:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          Data is not enriched with external sources unless explicitly permitted
          by the user, such as when logging in through Facebook, Google, or
          Apple. Consent is obtained for any data collection through these
          channels.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Organizational Anchoring:
        </h3>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          An individual is designated to oversee ethical data management. This
          role involves reviewing updates to ensure they benefit users, writing
          guidelines to prevent misuse, and conducting regular audits. We
          require partners to adhere to our data principles in all agreements.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          5. Equality
        </h2>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Support for Vulnerable Users:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          Unimayt provides features to support vulnerable users, such as
          creating specialized groups for individuals who may face challenges in
          making connections.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Safeguards and Reporting:
        </h3>
        <p className="text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          We have mechanisms to prevent and address inappropriate behavior.
          Users can block or report others, with multiple reports leading to a
          review and potential suspension of accounts. We balance privacy rights
          with community safety in these decisions.
        </p>
        <h3 className="text-sm sm:text-md font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Public Platforms:
        </h3>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          To protect user privacy, we do not engage in public dialogues on
          external platforms where user data could be exposed. We also avoid
          using data to train algorithms that could lead to discrimination or
          echo chambers.
        </p>

        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          We are dedicated to making social networking more ethical and
          transparent. If you have any suggestions or concerns regarding our
          data practices, please contact us at info@unimayt.com.
        </p>
        <p className="text-sm sm:text-base mb-6 text-unimayt ml-4 md:ml-0">
          Thank you for supporting our mission to prioritize user data and
          uphold high ethical standards
        </p>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default DataEthics;
