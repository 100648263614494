import useAuth from "../../../services/useAuth";
import { useMutation } from "@tanstack/react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { useState } from "react";
import EditProfileModal from "../../shared/profile/EditProfileModel";
import { useUserStore } from "../../../zustandStore/useUserStore";
import ScrollTiles from "./components/scrollTiles";
import NavigateTiles from "./components/navigateTiles";

interface SecondaryHeaderProps {
  editProfile?: boolean;
  search?: boolean;
  title?: string;
  dropdown?: boolean;
  setConversation?: any;
}

const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  editProfile = false,
  search = false,
  title,
  dropdown = false,
  setConversation,
}) => {
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const [isEditProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const location = useLocation();
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);
  const logoutMutation = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      localStorage.removeItem("userInfo");
      navigate("/login");
    },
    onError: (error) => {
      console.error("Error logging out:", error);
    },
  });

  return (
    <div className="min-w-full bg-unimayt">
      <header className="flex items-center p-3 justify-between overflow-x-hidden">
        <div className="flex flex-row items-center gap-2">
          {location.pathname === "/login" ? <ScrollTiles /> : <NavigateTiles />}
          <div
            onClick={() => {
              navigate("/home");
            }}
            className="font-inter cursor-pointer font-semibold text-lg md:text-xl lg:text-2xl leading-5 tracking-widest text-[#D8D9DB]"
          >
            UNIMAYT
          </div>
        </div>
        <div className="flex flex-1 justify-center items-center">
          <div className="text-[#D8D9DB] text-xl md:text-3xl font-bold">
            {title}
          </div>
        </div>
        <div className="flex items-center space-x-2 md:space-x-2">
          {search && (
            <>
              <div className="hidden sm:block relative rounded-lg">
                <input
                  type="text"
                  placeholder="Search"
                  className="p-2 pl-4 border-white border-1 placeholder:text-black w-[44dvh] lg:w-[50dvh] bg-[#D8D9DB] rounded-xl"
                />
                <div className="absolute right-12 top-2 h-6 w-[2px] bg-black" />
                <IoSearch
                  className="absolute right-4 top-3 text-black"
                  size={20}
                />
              </div>
              <IoSearch
                className="block sm:hidden text-[#D8D9DB] cursor-pointer"
                size={24}
              />
            </>
          )}
          {dropdown && (
            <IoMdSettings
              className="text-[#D8D9DB] cursor-pointer settings-button"
              size={30}
              onClick={() => {
                setShowSettings((prevState: boolean) => !prevState);
              }}
            />
          )}
          {showSettings && (
            <div
              className="absolute top-16 right-4 rounded-2xl bg-[#D8D9DB] shadow-md pr-4 pl-4 w-42 settings-popup"
              style={{ zIndex: 1 }}
            >
              <div className="flex flex-col font-semibold text-unimayt text-xl">
                {editProfile && (
                  <>
                    <button
                      className="py-1 px-1"
                      onClick={() => {
                        setEditProfileModalOpen(
                          (prevState: boolean) => !prevState
                        );
                        setShowSettings((prevState: boolean) => !prevState);
                      }}
                    >
                      Edit Profile
                    </button>
                    <hr className="border-black" />
                  </>
                )}

                <button
                  onClick={() => {
                    // setConversation(null);
                    setSelectedUser(null);
                    logoutMutation.mutateAsync();
                  }}
                  className="py-1"
                >
                  Logout
                </button>
                <hr className="border-black" />
                <Link to="/contact-us">
                  <button className="py-1 px-8">Help</button>
                </Link>
              </div>
              <div className="absolute top-2 right-2">
                <span className="block w-3 h-3 bg-[#D8D9DB] rotate-45"></span>
              </div>
            </div>
          )}
        </div>
      </header>

      {/* Conditionally render the EditProfileModal */}
      {isEditProfileModalOpen && (
        <EditProfileModal
          isEditing={isEditProfileModalOpen}
          setIsEditing={setEditProfileModalOpen}
        />
      )}
    </div>
  );
};

export default SecondaryHeader;
