import { FaFacebook, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Australia from "../../assets/Logos/FlagLogos/Australia.svg";
import Canada from "../../assets/Logos/FlagLogos/Canada.svg";
import European_Union from "../../assets/Logos/FlagLogos/European_Union.svg";
import India from "../../assets/Logos/FlagLogos/India.svg";
import United_Kingdom from "../../assets/Logos/FlagLogos/United_Kingdom.svg";
import United_States from "../../assets/Logos/FlagLogos/United_States.svg";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-unimayt text-white py-8 border-t flex justify-center items-center md:rounded-2xl md:mr-2">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg">UNIMAYT</h4>
            <p className="text-sm">© 2024 Unimayt.</p>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg mb-4">What We Do</h4>
            <ul className="text-sm space-y-2">
              <li>
                <a href="#" className="hover:underline">
                  Student Services
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Student Recruitment
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Unimayt Abroad Network
                </a>
              </li>
              <li>
                <a href="/contact-us" className="hover:underline">
                  Support
                </a>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg mb-4">Site Map</h4>
            <ul className="text-sm space-y-2 hover:cursor-pointer">
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/ourImpact");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Our Impact
                  {/* </Link> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/dataEthics");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Data Ethics
                  {/* </Link> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/privacyPolicy");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Privacy Policy
                  {/* </Link> */}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/TermsAndConditions");
                  }}
                >
                  {/* <Link to="/TermsAndConditions" className="hover:underline"> */}
                  Terms and Conditions
                  {/* </Link> */}
                </div>
              </li>
            </ul>
          </div>

          <div className="mb-6 lg:mb-0">
            <h4 className="font-semibold text-lg mb-4">About Us</h4>
            <ul className="text-sm space-y-2">
              <li>
                <a href="mailto:info@unimayt.com">info@unimayt.com</a>
              </li>
              <ul>
                <div className="flex flex-wrap gap-x-1">
                  <div className="flex items-center">
                    <img
                      src={Australia}
                      alt="Australia"
                      className="w-8 h-8 mr-1"
                    />
                  </div>
                  <div className="flex items-center">
                    <img src={Canada} alt="Canada" className="w-8 h-8 mr-1" />
                  </div>
                  <div className="flex items-center">
                    <img
                      src={European_Union}
                      alt="European Union"
                      className="w-8 h-5 mr-1"
                    />
                  </div>
                  <div className="flex items-center">
                    <img src={India} alt="India" className="w-8 h-5 mr-1" />
                  </div>
                  <div className="flex items-center">
                    <img
                      src={United_Kingdom}
                      alt="United Kingdom"
                      className="w-8 h-8 mr-1"
                    />
                  </div>
                  <div className="flex items-center">
                    <img
                      src={United_States}
                      alt="United_States"
                      className="w-8 h-8 mr-1"
                    />
                  </div>
                </div>
              </ul>

              <li className="pt-4">
                <Link to="/contact-us">
                  <button className="bg-unimaytLight hover:border-1 text-white font-semibold py-2 px-4 rounded">
                    Connect to Our Team
                  </button>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-lg mb-4">Let's Stay Connected</h4>
            <ul className="text-sm space-x-4 flex flex-row">
              <li>
                <a
                  href="https://www.linkedin.com/company/unimayt/"
                  className=""
                >
                  <FaLinkedin size={25} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/unimayt_/"
                  className="hover:underline"
                >
                  <FaInstagramSquare size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
