import Footer from "../Footer";
import React from "react";
import SecondaryHeader from "../header/SecondaryHeader";

const PrivacyPolicies = () => {
  return (
    <div>
      <SecondaryHeader search dropdown />

      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 xl:px-48 py-6 sm:py-8 md:py-12">
        <h1 className="flex items-center justify-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-8 sm:mb-10 md:mb-12 lg:mb-16 mt-6 text-unimayt">
          Privacy Policy
        </h1>
        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          Effective Date: 16th September, 2024
        </h2>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          At Unimayt, we value your privacy and are committed to protecting your
          personal data. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          and use our services, including our mobile application (the "App"). By
          using our services, you agree to the practices described in this
          policy.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-4 text-unimayt ml-4 md:ml-0">
          1. Information We Collect
        </h2>
        <h1 className="text-md sm:text-md font-bold text-unimayt ml-4 md:ml-0">
          1.1 Personal Data You Provide
        </h1>
        <ul className="list-disc list-inside text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">
              Profile Information:{" "}
            </strong>
            <span className="text-unimayt">
              Name, email address, password, date of birth, gender, city of
              origin, country of origin, degree program, and other details you
              choose to provide.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              Social Media Data:{" "}
            </strong>
            <span className="text-unimayt">
              If you use a social media login, we may receive basic information
              from the social media provider, such as your email address.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Profile Content: </strong>
            <span className="text-unimayt">
              Any additional information, including profile pictures and
              background details, that you choose to add to your profile.
            </span>
          </li>
        </ul>

        <h3 className="text-md sm:text-md font-bold text-unimayt ml-4 md:ml-0">
          1.2 Data from Higher Education Institutions
        </h3>
        <p className="text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          If your institution uses our platform, we may receive personal data
          from them, such as your name, email address, and other relevant
          details prefilled by the institution.
        </p>

        <h1 className="text-md sm:text-md font-bold text-unimayt ml-4 md:ml-0">
          1.3 Usage Data
        </h1>
        <ul className="list-disc list-inside text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">App Usage: </strong>
            <span className="text-unimayt">
              Frequency, duration, and activity within the App.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              Communication Data:{" "}
            </strong>
            <span className="text-unimayt">
              Messages and content shared within the App, including chat
              messages, posts, and flags for inappropriate content.
            </span>
          </li>
        </ul>

        <h1 className="text-md sm:text-md font-bold text-unimayt ml-4 md:ml-0">
          1.4 Technical Data
        </h1>
        <ul className="list-disc list-inside text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">
              Device Information:{" "}
            </strong>
            <span className="text-unimayt">
              IP address, device type, operating system, and browser type.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Log Data: </strong>
            <span className="text-unimayt">
              Information about your use of our services, including access
              times, error logs, and other diagnostic data.
            </span>
          </li>
        </ul>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          2. How We Use Your Information
        </h2>
        <p className="text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          We use your personal data for the following purposes:
        </p>
        <ul className="list-disc list-inside text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">
              Account Management:{" "}
            </strong>
            <span className="text-unimayt">
              To create and manage your account, including verifying your
              identity and providing access to our services.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Connecting Users: </strong>
            <span className="text-unimayt">
              To enable communication and interaction between students and
              institutions, and to support networking and community building.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              Content Moderation:{" "}
            </strong>
            <span className="text-unimayt">
              To ensure the safety and integrity of the App by moderating
              content and addressing inappropriate behavior.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              Service Improvements:{" "}
            </strong>
            <span className="text-unimayt">
              To analyze usage patterns, gather feedback, and improve our
              services and features.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Communication: </strong>
            <span className="text-unimayt">
              To send notifications about new features, updates, and changes to
              our Privacy Policy.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Legal Compliance: </strong>
            <span className="text-unimayt">
              To comply with legal obligations and respond to legal requests or
              claims.
            </span>
          </li>
        </ul>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          3. Sharing Your Information
        </h2>
        <p className="text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          We may share your personal data in the following circumstances:
        </p>
        <ul className="list-disc list-inside text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">
              With Institutions:{" "}
            </strong>
            <span className="text-unimayt">
              Higher education institutions may access aggregated or personal
              data about their students through their administrator accounts.
              Institutions may use this information for their purposes,
              including recruitment and communication.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              With Service Providers:{" "}
            </strong>
            <span className="text-unimayt">
              We use third-party service providers for hosting, email
              communication, chat hosting, and software development. These
              providers are bound by contractual obligations to protect your
              data.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              For Legal Reasons:{" "}
            </strong>
            <span className="text-unimayt">
              We may disclose your data to comply with legal obligations,
              respond to legal requests, or protect our rights and safety
            </span>
          </li>
        </ul>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          4. Data Security
        </h2>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          We implement industry-standard security measures to protect your
          personal data from unauthorized access, use, or disclosure. Despite
          our efforts, no data transmission over the internet or electronic
          storage method is completely secure.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          5. Data Retention
        </h2>
        <p className="text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          We retain your personal data for as long as necessary to fulfill the
          purposes for which it was collected. Typically:
        </p>
        <ul className="list-disc list-inside text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">Student Data: </strong>
            <span className="text-unimayt">
              Stored for five years after your last login or as specified by
              your institution.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">
              Administrator Data:{" "}
            </strong>
            <span className="text-unimayt">
              Stored for as long as the administrator is active with the
              institution and for two years after leaving the institution.
            </span>
          </li>
        </ul>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          6. Your Rights
        </h2>
        <p className="text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          Under applicable data protection laws, you have the following rights:
        </p>
        <ul className="list-disc list-inside text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          <li>
            <strong className="text-unimayt text-sm">Access </strong>
            <span className="text-unimayt">
              Request a copy of your personal data.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Correction </strong>
            <span className="text-unimayt">
              Request correction of inaccurate or incomplete data.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Deletion </strong>
            <span className="text-unimayt">
              Request deletion of your personal data under certain conditions
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Data Portability: </strong>
            <span className="text-unimayt">
              Request a copy of your data in a structured, commonly used format.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Objection: </strong>
            <span className="text-unimayt">
              Object to the processing of your personal data for specific
              purposes.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Restriction </strong>
            <span className="text-unimayt">
              Request restriction of processing in certain circumstances.
            </span>
          </li>
          <li>
            <strong className="text-unimayt text-sm">Withdraw Consent: </strong>
            <span className="text-unimayt">
              Withdraw any consent you have given for processing your data.
            </span>
          </li>
        </ul>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          7. Changes to This Privacy Policy
        </h2>
        <p className="text-sm sm:text-base mb-8 text-unimayt ml-4 md:ml-0">
          We may update this Privacy Policy from time to time. Significant
          changes will be communicated to you through notifications in the App
          or other means.
        </p>

        <h2 className="text-base sm:text-lg font-bold mb-2 text-unimayt ml-4 md:ml-0">
          8. Contact Us
        </h2>
        <p className="text-sm sm:text-base mb-4 text-unimayt ml-4 md:ml-0">
          If you have any questions about this Privacy Policy or wish to
          exercise your rights, please contact us at:
        </p>
        <ul className="list-disc list-inside text-sm sm:text-base mb-2 text-unimayt ml-4 md:ml-0">
          <div>
            <strong className="text-unimayt text-sm">Company Name: </strong>
            <span className="text-unimayt">Unimayt</span>
          </div>
          <div>
            <strong className="text-unimayt text-sm">Email Address: </strong>
            <span className="text-unimayt">info@unimayt.com</span>
          </div>
          <div>
            <strong className="text-unimayt text-sm">Address: </strong>
            <span className="text-unimayt">5A/4, NIT, FARIDABAD, HARYANA</span>
          </div>
          <div className="pt-4">
            <strong className="text-unimayt text-sm">Last Updated: </strong>
            <span className="text-unimayt ">16th september, 2024</span>
          </div>
        </ul>
      </div>

      <div className="w-full md:pl-2 md:py-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicies;
