import MiniResources from "../components/shared/MiniResources";
import React from "react";
import getAuthState from "../hooks/getAuthState";

import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import MessagingWindow from "../components/shared/chat/MessagingWindow";
import Footer from "../components/layout/Footer";

import { useUserStore } from "../zustandStore/useUserStore";

import { PiChatsBold } from "react-icons/pi";
import { getStoredConversation } from "../lib/conversation";
import { MdClose } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";

const Resources: React.FC = () => {
  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const chatRoomOpen = useUserStore((state) => state.chatRoomOpen);

  if (!getAuthState()) return <div>Loading...</div>;
  return (
    <div className="flex flex-col min-h-screen bg-[#f1f5fb]">
      <div className="sticky top-0 z-10">
        <SecondaryHeader dropdown search />
      </div>

      <div className="flex flex-1 gap-x-2 sm:px-4 lg:px-2 py-2">
        <div className="md:!w-9/12 w-full overflow-y-scroll no-scrollbar">
          <MiniResources />
        </div>

        <div className="w-3/12 hidden md:block lg:block fixed right-2 top-16 bottom-1 mt-1 bg-white">
          <MessagingWindow conversation={getStoredConversation()} />
        </div>

        <div
          className={`${
            chatRoomOpen
              ? "fixed inset-0 z-50 w-full h-full bg-white"
              : "hidden"
          } lg:hidden`}
        >
          <div className="relative h-full p-1">
            <MessagingWindow conversation={getStoredConversation()} />
            <button
              className="absolute top-7 right-10 text-lg text-white font-bold"
              onClick={() => setChatRoomOpen(null)}
            >
              <IoMdArrowRoundBack />
            </button>
          </div>
        </div>
      </div>

      {!chatRoomOpen && (
        <button
          className="fixed bottom-6 right-3 lg:hidden bg-Gray text-unimayt p-3 rounded-full shadow-lg flex flex-row gap-x-2"
          onClick={() => setChatRoomOpen(getStoredConversation()[0])}
        >
          <PiChatsBold size={25} />
        </button>
      )}
      {/* <div className="md:w-9/12 w-full md:p-2">
        <Footer />
      </div> */}
    </div>
  );
};

export default Resources;
