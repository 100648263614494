import { XIcon } from "lucide-react";
import { Avatar, AvatarFallback } from "../../ui/Avatar";
import { Button } from "../../ui/Button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getAuthState from "../../../hooks/getAuthState";
import { cn } from "../../../lib/utils";
import { Message } from "../../../types/ChatsInterface";
import { useUserStore } from "../../../zustandStore/useUserStore";
import { useQueryClient } from "@tanstack/react-query";
import { IoSend } from "react-icons/io5";
import { ScrollArea } from "../../ui/ScrollArea";

interface ChatRoomInterface {
  messages: Message[];
  newMessage: any;
  setMessages: any;
}

const ChatRoom = ({ messages, newMessage, setMessages }: ChatRoomInterface) => {
  const [inputMsg, setInputMsg] = useState("");
  const [localChats, setLocalChats] = useState<any[]>([]);

  const selectedUser = useUserStore((state) => state.selectedUser);
  const setSelectedUser = useUserStore((state) => state.setSelectedUser);

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, localChats, scrollToBottom]);

  const typeOfConversation = () => {
    if (selectedUser?.users) {
      console.log(selectedUser);
      const recipient = selectedUser.users.find(
        (user: any) => user.guid !== getAuthState().guid
      );
      return recipient;
    } else {
      return selectedUser;
    }
  };

  const queryClient = useQueryClient();

  const handleSendMessage = useCallback(() => {
    if (inputMsg.trim() === "") return;
    const optimisticMsge = [
      {
        type: "new_message",
        user_guid: getAuthState().guid,
        chat_guid: selectedUser?.chat_guid,
        content: inputMsg,
      },
    ];
    setLocalChats((prev) => [...prev, ...optimisticMsge]);
    newMessage(inputMsg);
    setInputMsg("");
  }, [inputMsg, newMessage, selectedUser?.chat_guid]);

  const memoizedMessages = useMemo(() => {
    const uniqueMessageGuids = new Set();
    setLocalChats([]);
    return messages.filter((msg: any) => {
      if (uniqueMessageGuids.has(msg.message_guid)) {
        return false;
      }
      uniqueMessageGuids.add(msg.message_guid);
      return true;
    });
  }, [messages]);

  return (
    <>
      <div className="h-16 border-b border-unimaytLight px-4 py-2 flex items-center justify-between bg-unimaytLight rounded-t-2xl">
        <div className="flex items-center gap-x-3">
          <Avatar className="h-8 w-8">
            <AvatarFallback className="border-2 h-full w-full font-bold text-lg border-unimayt">
              {typeOfConversation()
                ?.first_name.split(" ")
                .map((n: any) => n[0])
                .join("")}
            </AvatarFallback>
          </Avatar>
          <div>
            <h3 className="text-2sm font-semibold">
              {typeOfConversation()?.first_name}
            </h3>
            <p className="text-xs text-muted-foreground">
              {typeOfConversation()?.username}
            </p>
          </div>
        </div>
        <Button
          onClick={() => {
            setMessages([]);
            queryClient.invalidateQueries({ queryKey: ["chats"] });
            setSelectedUser(null);
          }}
          variant="ghost"
          size="icon"
          className="h-8 w-8 font-bold hover:bg-unimaytLight"
        >
          <XIcon className="h-4 w-4 text-white fixed right-5" />
        </Button>
      </div>

      {/* {messages.length === 0 ? (
        <div className="flex items-center justify-center">
          <img
            src={"/icons/ripples.svg"}
            alt={"loading"}
            height={1}
            width={1}
            className="w-20 h-20 object-contain"
          />
        </div>
      ) : ( */}
      <ScrollArea className="flex-grow bg-white">
        <div className="flex flex-1 flex-col gap-2 rounded-md px-2 pt-2">
          <div className="flex size-full flex-1">
            <div className="relative -mr-4 flex flex-1 flex-col-reverse overflow-y-hidden">
              <div className="flex h-full w-full flex-col justify-end gap-y-1 pb-2 overflow-y-scroll pr-4">
                {[
                  ...memoizedMessages,
                  ...localChats, // Spread localChats
                ].map(
                  (message: any, index: number) =>
                    message &&
                    message.content &&
                    message.content.trim() !== "" && (
                      <div
                        key={`${message.sender}-${message.timestamp}-${index}`}
                        className={cn(
                          "chat-box max-w-72 break-words px-3 py-2 shadow-lg",
                          message.user_guid === getAuthState().guid
                            ? "self-end rounded-[16px_16px_0_16px] bg-primary/85 text-primary-foreground/75"
                            : "self-start rounded-[16px_16px_16px_0] mb-1 bg-secondary"
                        )}
                      >
                        <div>{message.content}</div>
                      </div>
                    )
                )}
                <div ref={scrollRef} />
              </div>
            </div>
          </div>
        </div>
      </ScrollArea>
      {/* )} */}
      <div className="bg-Gray rounded-2xl border border-Gray m-0 lg:m-1">
        <div className="flex items-center justify-center">
          <input
            type="text"
            placeholder="Type a message..."
            value={inputMsg}
            onChange={(e) => setInputMsg(e.target.value)}
            className="flex-grow ml-1 bg-Gray outline-none"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <div className="bg-unimayt rounded-lg flex items-center justify-center m-1 w-14 h-9 p-4">
            <button
              onClick={() => {
                handleSendMessage();
              }}
              className="text-white"
            >
              <IoSend className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRoom;
