import React, { useCallback, useEffect, useState } from "react";
import getAuthState from "../hooks/getAuthState";
import SecondaryHeader from "../components/layout/header/SecondaryHeader";
import MyProfile from "../components/shared/profile/MyProfile";
import MessagingWindow from "../components/shared/chat/MessagingWindow";
import UseUsers from "../services/useUsers";
import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "../zustandStore/useUserStore";
import { useLocalStorage } from "usehooks-ts";
import { Conversation } from "../types/ChatsInterface";
import UseChat from "../services/useChat";
import { MdChat, MdClose } from "react-icons/md";
import { PiChatsBold } from "react-icons/pi";
import Footer from "../components/layout/Footer";
import { IoMdArrowRoundBack } from "react-icons/io";

const Home: React.FC = () => {
  const { getUsers } = UseUsers();

  const { getChats } = UseChat();

  const setUsers = useUserStore((state) => state.setUsers);

  const [conversation, setConversation] = useLocalStorage<Conversation[]>(
    "conversation",
    []
  );

  const setChatRoomOpen = useUserStore((state) => state.setChatRoomOpen);
  const chatRoomOpen = useUserStore((state) => state.chatRoomOpen);
  const selectedConversation = conversation[0];

  const {
    data: getUsersQuery,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const {
    data: getConversationsQuery,
    isSuccess: isSuccessConversationsQuery,
  } = useQuery({
    queryKey: ["chats"],
    queryFn: getChats,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const getConversation = useCallback(
    (data: any) => {
      setConversation(data?.data?.chats);
    },
    [setConversation]
  );

  useEffect(() => {
    if (isSuccessConversationsQuery && getConversationsQuery !== undefined) {
      if (
        getConversationsQuery?.data != null ||
        getConversationsQuery?.data !== undefined
      ) {
        getConversation(getConversationsQuery);
      }
    }
  }, [getConversation, getConversationsQuery, isSuccessConversationsQuery]);

  useEffect(() => {
    if (isSuccess && getUsersQuery !== undefined) {
      setUsers(getUsersQuery?.data);
    }
  }, [setUsers, getUsersQuery, isSuccess]);

  if (!getAuthState()) return <div>Loading...</div>;

  return (
    <div className="flex flex-col min-h-screen bg-[#f1f5fb]">
      <div className="sticky top-0 z-10">
        <SecondaryHeader
          setConversation={setConversation}
          dropdown
          search
          editProfile
        />
      </div>

      <div className="flex flex-1 gap-x-2 lg:pr-8 lg:pl-2 py-2">
        <div className="md:!w-9/12 w-full overflow-y-scroll no-scrollbar">
          <MyProfile
            setConversation={setConversation}
            conversation={conversation}
            isLoading={isLoading}
          />
        </div>

        <div className="w-3/12 hidden md:block lg:block fixed right-2 top-16 bottom-1 mt-1 bg-white">
          <MessagingWindow
            conversation={conversation}
            setConversation={setConversation}
          />
        </div>

        <div
          className={`${
            chatRoomOpen
              ? "fixed inset-0 z-50 w-full h-full bg-white"
              : "hidden"
          } lg:hidden`}
        >
          <div className="relative h-full p-1">
            <MessagingWindow
              conversation={conversation}
              setConversation={setConversation}
            />
            <button
              className="absolute top-7 right-10 text-lg text-white font-bold"
              onClick={() => setChatRoomOpen(null)}
            >
              <IoMdArrowRoundBack />
            </button>
          </div>
        </div>
      </div>

      {!chatRoomOpen && (
        <button
          className="fixed bottom-6 right-3 lg:hidden bg-Gray text-unimayt p-3 rounded-full shadow-lg flex flex-row gap-x-2"
          onClick={() => setChatRoomOpen(selectedConversation)}
        >
          <PiChatsBold size={25} />
        </button>
      )}

      <div className="md:w-9/12 w-full md:p-2 ">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
