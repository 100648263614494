import React, { useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowDown,
  MdClose,
} from "react-icons/md";
import magoosh from "../../assets/magoosh.png";
import coursera from "../../assets/coursera.png";
import udemy from "../../assets/udemy.jpg";
import duolingo from "../../assets/duolingo.png";
import chopras from "../../assets/chopras.avif";
import idp from "../../assets/idp.png";
import shiksha from "../../assets/shiksha.jpg";
import timesEducation from "../../assets/timesEducation.png";
import qsWorldRanking from "../../assets/qsWorldRanking.png";
import educationUSA from "../../assets/educationUSA.jpg";
import britishCouncil from "../../assets/britishCouncil.png";
import daad from "../../assets/daad.png";
import icici from "../../assets/icici.jpg";
import kotak from "../../assets/kotak.jpg";
import makeMyTrip from "../../assets/makeMyTrip.jpg";
import booking from "../../assets/booking.png";
import scholarships from "../../assets/scholarships.jpg";
import fastweb from "../../assets/fastweb.svg";
import kayak from "../../assets/kayak.png";
import scyscanner from "../../assets/scyscanner.jpg";
import ivisa from "../../assets/ivisa.png";
import visahq from "../../assets/visahq.jpg";
import amber from "../../assets/amber.png";
import housinganywhere from "../../assets/housinganywhere.png";
import roombuddy from "../../assets/roombuddy.png";
import universityliving from "../../assets/universityliving.jpg";
import uniplaces from "../../assets/uniplaces.png";
import airbnb from "../../assets/airbnb.png";
import target from "../../assets/target.jpg";
import walmart from "../../assets/walmart.png";
import citymarket from "../../assets/citymarket.jpg";
import apple from "../../assets/apple.png";
import amazon from "../../assets/amazon.png";
import isic from "../../assets/isic.png";
import unidays from "../../assets/unidays.png";
import ikea from "../../assets/ikea.png";
import Footer from "../layout/Footer";

const MiniResources: React.FC = () => {
  const [activeTopic, setActiveTopic] = useState<string | null>(null);
  const [activeSubtopic, setActiveSubtopic] = useState<string | null>(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState<string | null>(null);
  const [expandedTopic, setExpandedTopic] = useState<string | null>(null);

  const mainTopics = [
    {
      title: "Admissions",
      subtopics: ["Courses", "Counselling", "Rankings"],
    },
    {
      title: "Finances",
      subtopics: ["Scholarships", "Loans"],
    },
    {
      title: "Travel",
      subtopics: ["Air Tickets", "Visa", "Insurance", "Forex"],
    },
    {
      title: "Accommodations",
      subtopics: ["Student Housing", "Temporary Stay"],
    },
    {
      title: "Shopping",
    },
  ];

  const handleTopicClick = (title: string) => {
    if (title === "Shopping") {
      setSelectedSubTopic(title); // Open popup directly for Shopping
    } else {
      setExpandedTopic(expandedTopic === title ? null : title);
      setActiveTopic(title);
      setActiveSubtopic(null);
      setSelectedSubTopic(null);
    }
  };

  const handleSubtopicClick = (topic: string, subtopic: string) => {
    setActiveTopic(topic);
    setActiveSubtopic(subtopic);
    setSelectedSubTopic(subtopic);
  };

  const closePopup = () => {
    setSelectedSubTopic(null);
  };

  const getResourcesForSubtopic = (subtopic: string) => {
    switch (subtopic) {
      case "Courses":
        return [
          { name: "Magoosh", url: "https://magoosh.com/", img: magoosh },
          { name: "Coursera", url: "https://www.coursera.org/", img: coursera },
          { name: "Udemy", url: "https://www.udemy.com/", img: udemy },
          { name: "Duolingo", url: "https://www.duolingo.com/", img: duolingo },
        ];
      case "Counselling":
        return [
          { name: "The Chopras", url: "https://tcglobal.com/", img: chopras },
          { name: "IDP", url: "https://www.idp.com/", img: idp },
          {
            name: "Shiksha.com",
            url: "https://www.shiksha.com/",
            img: shiksha,
          },
        ];
      case "Rankings":
        return [
          {
            name: "Times Education",
            url: "https://www.timeshighereducation.com/",
            img: timesEducation,
          },
          {
            name: "QS World Ranking",
            url: "https://www.topuniversities.com/",
            img: qsWorldRanking,
          },
          {
            name: "EducationUSA",
            url: "https://educationusa.state.gov/",
            img: educationUSA,
          },
          {
            name: "British Council",
            url: "https://www.britishcouncil.org/",
            img: britishCouncil,
          },
          { name: "DAAD", url: "https://www.daad.de/en/", img: daad },
        ];

      case "Scholarships":
        return [
          {
            name: "Scholarships",
            url: "http://www.scholarship.com/",
            img: scholarships,
          },
          { name: "Fastweb", url: "https://www.fastweb.com/", img: fastweb },
        ];
      case "Loans":
        return [
          { name: "ICICI", url: "https://www.icicibank.com/", img: icici },
          {
            name: "Kotak",
            url: "https://www.kotak.com/en/home.html",
            img: kotak,
          },
        ];

      case "Air Tickets":
        return [
          {
            name: "Make My Trip",
            url: "https://www.makemytrip.com/",
            img: makeMyTrip,
          },
          { name: "Booking", url: "https://www.booking.com/", img: booking },
          {
            name: "Kayak",
            url: "https://www.kayak.co.in/",
            img: kayak,
          },
          {
            name: "Scy Scanner",
            url: "https://www.skyscanner.net/media/media-assets",
            img: scyscanner,
          },
        ];
      case "Visa":
        return [
          {
            name: "Ivisa",
            url: "https://ivisatravel.com/",
            img: ivisa,
          },
          {
            name: "Visahq",
            url: "https://www.visahq.in/",
            img: visahq,
          },
          {
            name: "Travel Agency",
            url: "https://www.skyscanner.net/media/media-assets",
            img: scyscanner,
          },
        ];
      case "Insurance":

      case "Forex":
        return [
          { name: "ICICI", url: "https://www.icicibank.com/", img: icici },
          {
            name: "Kotak",
            url: "https://www.kotak.com/en/home.html",
            img: kotak,
          },
        ];

      case "Student Housing":
        return [
          {
            name: "Amber",
            url: "https://amberstudent.com/",
            img: amber,
          },
          {
            name: "Housing Anywhere",
            url: "https://housinganywhere.com/",
            img: housinganywhere,
          },
          {
            name: "University Living",
            url: "https://www.universityliving.com/",
            img: universityliving,
          },
          {
            name: "Room Buddy",
            url: "https://roombuddy.com.au/",
            img: roombuddy,
          },
          {
            name: "Uniplaces",
            url: "https://www.uniplaces.com/",
            img: uniplaces,
          },
        ];
      case "Temporary Stay":
        return [
          {
            name: "Airbnb",
            url: "https://www.airbnb.co.in/",
            img: airbnb,
          },
        ];

      case "Shopping":
        return [
          {
            name: "Target",
            url: "https://corporate.target.com/",
            img: target,
          },
          {
            name: "Walmart",
            url: "https://www.walmart.com/",
            img: walmart,
          },
          {
            name: "City Market",
            url: "https://www.citymarket.com/",
            img: citymarket,
          },
          {
            name: "Apple",
            url: "https://www.apple.com/in/",
            img: apple,
          },
          {
            name: "Ikea",
            url: "https://www.ikea.com/",
            img: ikea,
          },
          {
            name: "Unidays",
            url: "https://www.myunidays.com/",
            img: unidays,
          },
          {
            name: "Amazon",
            url: "https://www.amazon.in/student-discount/",
            img: amazon,
          },
          {
            name: "ISIC",
            url: "https://www.isic.org/",
            img: isic,
          },
        ];
      default:
        return [];
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="w-full p-2 flex-grow">
        <div className="text-2xl lg:text-4xl font-bold mb-4 text-[#1B302E] text-right pr-4 lg:pr-14 md:pr-0">
          Resources
        </div>
        <div className="flex flex-col">
          <div className="w-full text-right lg:pr-10">
            <div className="text-lg lg:text-2xl lg:pt-2">
              {mainTopics.map((topic) => (
                <div key={topic.title} className="-my-2 lg:my-2">
                  <div
                    className={`cursor-pointer py-2 px-4 rounded-md transition-all ease-in-out duration-300 flex items-center justify-end ${
                      activeTopic === topic.title
                        ? "text-[#1B302E] font-bold"
                        : "text-[#2a4e4b] font-semibold"
                    } ${activeTopic !== topic.title ? "opacity-50" : ""}`}
                    onMouseEnter={() => setActiveTopic(topic.title)}
                    onMouseLeave={() => setActiveTopic(null)}
                    onClick={() => handleTopicClick(topic.title)}
                  >
                    <span className="ml-2">
                      {/* Change arrow direction for "Shopping" */}
                      {topic.title === "Shopping" &&
                      selectedSubTopic === "Shopping" ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : expandedTopic === topic.title ? (
                        <MdOutlineKeyboardArrowDown />
                      ) : (
                        <MdOutlineKeyboardArrowLeft />
                      )}
                    </span>
                    {topic.title}
                  </div>
                  {expandedTopic === topic.title &&
                    topic.subtopics &&
                    topic.subtopics.length > 0 && (
                      <ul className="lg:pl-16 text-sm lg:text-[18px] font-semibold text-left text-[#1B302E] flex flex-col gap-2 justify-end">
                        {topic.subtopics.map((subtopic, index) => (
                          <li
                            key={subtopic}
                            className={`cursor-pointer transition-all ease-in-out duration-300 flex items-center justify-start ${
                              activeSubtopic === subtopic
                                ? "text-[#1B302E] font-bold"
                                : "opacity-70 text-[#2a4e4b] font-semibold"
                            } hover:opacity-100 hover:text-[#1B302E]`}
                            onClick={() => {
                              handleSubtopicClick(topic.title, subtopic);
                              if (index === topic.subtopics.length - 1) {
                                setSelectedSubTopic(subtopic);
                              }
                            }}
                          >
                            {subtopic}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Popup for the selected subtopic */}
      {selectedSubTopic && (
        <div className="absolute bottom-16 left-2 right-2 lg:bottom-64 lg:left-3 lg:right-3 border-2 border-white lg:w-[73dvw] px-2 lg:pl-10 py-2 lg:py-6 bg-gradient-to-r from-[#C33764] to-[#1d2671] rounded-lg shadow-[0px_0px_15px_black] z-50">
          <div className="relative w-full">
            <button
              className="absolute right-2 top-2 lg:top-0 lg:right-2 text-xl font-bold text-white"
              onClick={closePopup}
            >
              <MdClose />
            </button>
            <h2 className="text-2xl font-bold mb-4 lg:mb-4 text-white">
              <span className="text-3xl font-bold">{selectedSubTopic[0]}</span>
              {selectedSubTopic.slice(1)}
            </h2>

            {/* Grid for resources */}
            <div className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-8 gap-2 mt-6 lg:mt-2 mr-6">
              {getResourcesForSubtopic(selectedSubTopic).map((resource) => (
                <a
                  href={resource.url}
                  key={resource.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <img
                    src={resource.img}
                    alt={resource.name}
                    className="w-12 h-12 mb-2 border border-white transform transition-transform duration-300 ease-in-out group-hover:scale-110 rounded-lg shadow-inner"
                  />
                  <span className="text-sm font-semibold text-white p-1 transform transition-transform duration-300 ease-in-out group-hover:scale-110">
                    {resource.name}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="w-full h-[58dvh] md:h-0"></div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default MiniResources;
